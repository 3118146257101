var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "copyright" }, [
      _vm._v("©Strategybrain Technology Ltd.")
    ]),
    _c("div", { staticClass: "links" }, [
      _c(
        "a",
        {
          class: [_vm.lang],
          attrs: {
            target: "_blank",
            href: "https://www.strategybrain.ca/privacy-policy/"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("PrivacyPolicy")) + " ")]
      ),
      _c(
        "a",
        {
          class: [_vm.lang],
          attrs: {
            target: "_blank",
            href: "https://www.strategybrain.ca/news/customer-success/handbook/"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("HelpCenter")) + " ")]
      ),
      _c(
        "a",
        {
          class: [_vm.lang],
          attrs: {
            target: "_blank",
            href: "https://www.strategybrain.ca/contact-us/"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("ContactUs")) + " ")]
      ),
      _c(
        "a",
        {
          class: [_vm.lang],
          attrs: {
            target: "_blank",
            href: "https://www.strategybrain.ca/faqs/"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("FAQ")) + " ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }