<template>
  <a-config-provider :locale="locale" :get-popup-container="popContainer">
    <router-view />
  </a-config-provider>
</template>

<script>
import { enquireScreen } from "./utils/util";
import { mapState, mapMutations } from "vuex";
import themeUtil from "@/utils/themeUtil";
import { getI18nKey } from "@/utils/routerUtil";

export default {
  name: "App",
  data() {
    return {
      locale: {},
    };
  },
  created() {
    // this.setHtmlTitle();
    const lang = localStorage.getItem("lang");
    // setTimeout(() => {
    console.log("---------------", lang);
    this.setLang(lang ? lang : this.lang);
    // }, 1000);
    this.setLanguage(lang ? lang : this.lang);
    this.getLangFonts(lang); // 获取语言字体

    enquireScreen((isMobile) => this.setDevice(isMobile));
  },
  mounted() {
    this.setWeekModeTheme(this.weekMode);
    // this.$message.success("teest");
    let token = localStorage.getItem("token");
    if (token) {
      this.getUserInfo();
    }
  },
  watch: {
    weekMode(val) {
      this.setWeekModeTheme(val);
    },
    lang(val) {
      this.setLanguage(val);
      //this.setHtmlTitle();
      console.log("---------", val);
      this.getLangFonts(val);
    },
    $route() {
      // this.setHtmlTitle();
    },
    "theme.mode": function (val) {
      let closeMessage = this.$message.loading(
        `您选择了主题模式 ${val}, 正在切换...`
      );
      themeUtil.changeThemeColor(this.theme.color, val).then(closeMessage);
    },
    "theme.color": function (val) {
      let closeMessage = this.$message.loading(
        `您选择了主题色 ${val}, 正在切换...`
      );
      themeUtil.changeThemeColor(val, this.theme.mode).then(closeMessage);
    },
    layout: function () {
      window.dispatchEvent(new Event("resize"));
    },
  },
  computed: {
    ...mapState("setting", ["layout", "theme", "weekMode", "lang"]),
  },
  methods: {
    ...mapMutations("setting", ["setDevice", "setLang"]),
    setWeekModeTheme(weekMode) {
      if (weekMode) {
        document.body.classList.add("week-mode");
      } else {
        document.body.classList.remove("week-mode");
      }
    },
    setLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang);
      switch (lang) {
        case "CN":
          this.locale =
            require("ant-design-vue/es/locale-provider/zh_CN").default;
          break;
        case "AR":
          this.locale =
            require("ant-design-vue/es/locale-provider/ar_EG").default;
          break;
        case "HK":
          this.locale =
            require("ant-design-vue/es/locale-provider/zh_TW").default;
          break;
        case "US":
        default:
          this.locale =
            require("ant-design-vue/es/locale-provider/en_US").default;
          break;
      }
    },
    setHtmlTitle() {
      const route = this.$route;
      const key =
        route.path === "/"
          ? "home.name"
          : getI18nKey(route.matched[route.matched.length - 1].path);
      document.title = process.env.VUE_APP_NAME + " | " + this.$t(key);
    },
    popContainer() {
      return document.getElementById("popContainer");
    },
    getLangFonts(lang) {
      this.$nextTick(() => {
        // 操作dom 如果当前是英文状态的话，修改字体
        if (lang === "US") {
          document.querySelector("body").style.fontFamily =
            "Manrope, sans-serif";
        } else {
          document.querySelector("body").style.fontFamily = "";
        }
      });
    },
  },
  destroyed() {
    clearInterval(this.getuserTimer);
  },
};
</script>

<style lang="less">
#popContainer {
  background: url(./assets/img/bg.png) no-repeat;
  background-size: 100% 100%;
}
</style>
