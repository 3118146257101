var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { class: ["admin-layout", "beauty-scroll"] },
    [
      _c("side-menu", {
        class: [_vm.fixedSideBar ? "fixed-side" : ""],
        attrs: {
          theme: _vm.theme.mode,
          menuData: _vm.sideMenuData,
          collapsed: _vm.collapsed,
          collapsible: true
        }
      }),
      _vm.fixedSideBar && !_vm.isMobile
        ? _c("div", {
            staticClass: "virtual-side",
            style:
              "width: " +
              _vm.sideMenuWidth +
              "; min-width: " +
              _vm.sideMenuWidth +
              ";max-width: " +
              _vm.sideMenuWidth +
              ";"
          })
        : _vm._e(),
      _c(
        "a-layout",
        { staticClass: "admin-layout-main beauty-scroll" },
        [
          _c("admin-header", {
            class: [
              {
                "fixed-tabs": _vm.fixedTabs,
                "fixed-header": _vm.fixedHeader,
                "multi-page": !_vm.multiPage
              }
            ],
            style: _vm.headerStyle,
            attrs: { menuData: _vm.headMenuData, collapsed: _vm.collapsed },
            on: { toggleCollapse: _vm.toggleCollapse }
          }),
          _c("a-layout-header", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.fixedHeader,
                expression: "fixedHeader"
              }
            ],
            class: [
              "virtual-header",
              {
                "fixed-tabs": _vm.fixedTabs,
                "fixed-header": _vm.fixedHeader,
                "multi-page": _vm.multiPage
              }
            ]
          }),
          _c(
            "a-layout-content",
            {
              staticClass: "admin-layout-content",
              style: "min-height: " + _vm.minHeight + "px;"
            },
            [
              _c(
                "div",
                { staticStyle: { position: "relative" } },
                [_vm._t("default")],
                2
              )
            ]
          ),
          _c(
            "a-layout-footer",
            { staticStyle: { padding: "0px", background: "#f0f3f0" } },
            [
              _c("page-footer", {
                attrs: {
                  "link-list": _vm.footerLinks,
                  copyright: _vm.copyright
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("GlobalModals")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }