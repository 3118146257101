<template>
  <div class="newapp-footer">
    <!-- <div v-if="current == 1">
      显示第 {{ current }} 至 {{ current * pageSize }} 条结果，共 {{ total }} 条
    </div>
    <div v-if="current > 1">
      显示第 {{ (current - 1) * pageSize + 1 }} 至
      {{ current * pageSize }} 条结果，共 {{ total }} 条
    </div> -->
    <a-pagination
      v-if="showPagination"
      size="large"
      @change="onPageChange"
      @showSizeChange="onPageSizeChange"
      :pageSizeOptions="pageSizeOptions"
      :current="current"
      :defaultPageSize="defaultPageSize"
      :total="total"
      showSizeChanger
      showQuickJumper
    />
  </div>
</template>

<script>
// import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
// import { Pagination } from "ant-design-vue";
export default {
  name: "app-footer",
  // components: {
  //   APagination: Pagination,
  // },
  props: {
    total: Number,
    current: {
      type: Number,
      default: 1,
    },
    defaultPageSize: {
      type: Number,
      default: 10,
    },
    pageSize: {
      type: Number,
      default: 0,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    showSizeChanger: Boolean,
    showQuickJumper: Boolean,
    pageSizeOptions: {
      type: Array,
      default: function () {
        return ["1", "10", "25", "50", "100"];
      },
    },
  },
  data() {
    return {
      // locale,
    };
  },
  methods: {
    onPageChange: function (page, pageSize) {
      this.$emit("onPageChange", page, pageSize);
    },
    onPageSizeChange: function (current, size) {
      this.$emit("onPageSizeChange", current, size);
    },
  },
  mounted() {},
  created() {},
};
</script>

<style lang="less" scoped>
.newapp-footer {
  height: @app-footer-height;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 20px;
  // position: absolute;
  // bottom: 8px;
  width: 100%;
  background-color: transparent;
  border: none;
  // border-top: 1px solid #e8e8e8;
  // background-color: white;
  // box-shadow: 0 -2px 4px rgba($color: #000000, $alpha: 0.2);
}
</style>
