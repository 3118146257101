var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "a-modal",
        {
          attrs: {
            title: "",
            centered: true,
            footer: null,
            maskClosable: false,
            dialogClass: "global-free-modal-class",
            closeIcon: function(h) {
              return h("svg-icon", {
                props: { iconClass: "d_close" },
                style: {
                  fontSize: "23px"
                }
              })
            }
          },
          on: { cancel: _vm.closeModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("div", { staticClass: "free-modal-img" }),
          _c("div", { staticClass: "free-modal-con" }, [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "title-txt", class: [_vm.lang] }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("freeModalTitle").replace("X", _vm.havedays)
                    ) +
                    " "
                )
              ]),
              _c("div", { staticClass: "title-tip", class: [_vm.lang] }, [
                _vm._v(" " + _vm._s(_vm.$t("freeModelSubTitle")) + " ")
              ])
            ]),
            _c("div", { staticClass: "tip-box", class: [_vm.lang] }, [
              _c(
                "div",
                {
                  staticClass: "tip-item",
                  class: [_vm.lang],
                  style: {
                    "text-align": _vm.lang == "AR" ? "right" : "left"
                  }
                },
                [
                  _c("svg-icon", {
                    staticClass: "tip-txt-icon",
                    style: {
                      "margin-left": _vm.lang == "AR" ? "8px" : "0"
                    },
                    attrs: { iconClass: "d_right" }
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("freeModelTipTxt1")) + " ")
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "tip-item",
                  class: [_vm.lang],
                  style: {
                    "text-align": _vm.lang == "AR" ? "right" : "left"
                  }
                },
                [
                  _c("svg-icon", {
                    staticClass: "tip-txt-icon",
                    style: {
                      "margin-left": _vm.lang == "AR" ? "8px" : "0"
                    },
                    attrs: { iconClass: "d_right" }
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("freeModelTipTxt2")) + " ")
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "tip-item",
                  class: [_vm.lang],
                  style: {
                    "text-align": _vm.lang == "AR" ? "right" : "left"
                  }
                },
                [
                  _c("svg-icon", {
                    staticClass: "tip-txt-icon",
                    style: {
                      "margin-left": _vm.lang == "AR" ? "8px" : "0"
                    },
                    attrs: { iconClass: "d_right" }
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("freeModelTipTxt3")) + " ")
                ],
                1
              )
            ]),
            _c(
              "div",
              {
                staticClass: "model-btn",
                class: [_vm.lang],
                on: { click: _vm.goPayStep1 }
              },
              [_vm._v(" " + _vm._s(_vm.$t("freeModeBtnTxt")) + " ")]
            ),
            _c(
              "div",
              {
                staticClass: "free-btn",
                class: [_vm.lang],
                on: { click: _vm.closeModal }
              },
              [_vm._v(" " + _vm._s(_vm.$t("freeModeBtnTxt1")) + " ")]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }