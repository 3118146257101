import enquireJs from "enquire.js";
// import Compressor from "compressorjs";

export function isDef(v) {
  return v !== undefined && v !== null;
}

/**
 * Remove an item from an array.
 */
export function remove(arr, item) {
  if (arr.length) {
    const index = arr.indexOf(item);
    if (index > -1) {
      return arr.splice(index, 1);
    }
  }
}
export function getRowSpanCount(data, key, target) {
  if (!Array.isArray(data)) return 1;
  data = data.map((_) => {
    let obj = {};
    for (let i = 0; i < key.length; i++) {
      obj[key[i]] = _[key[i]];
    }
    return obj;
  }); // 只取出筛选项
  let preValue = data[0];
  const res = [[preValue]]; // 放进二维数组里
  let index = 0; // 二维数组下标
  for (let i = 1; i < data.length; i++) {
    if (
      data[i][key[0]] &&
      data[i][key[0]] === preValue[key[0]] &&
      data[i][key[1]] === preValue[key[1]]
    ) {
      // 相同放进二维数组
      res[index].push(data[i]);
    } else {
      // 不相同二维数组下标后移
      index += 1;
      res[index] = [];
      res[index].push(data[i]);
      preValue = data[i];
    }
  }
  const arr = [];
  res.forEach((_) => {
    const len = _.length;
    for (let i = 0; i < len; i++) {
      arr.push(i === 0 ? len : 0);
    }
  });
  return arr[target];
}

export function objectToArray(objet) {
  let arr = [];
  for (let x in objet) {
    let obj = {
      code: x,
      name: objet[x],
    };
    arr.push(obj);
  }
  return arr;
}

export function arrayToObject(arr) {
  let obj = {};
  arr.forEach((item) => {
    obj[item.code] = item.name;
  });
  return obj;
}

/**
 * 获取图片长宽
 * @param {*} img
 */
export function checkImageWH(
  img,
  callback = () => {
    return true;
  }
) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const image = new Image();
    image.onload = function () {
      if (callback({ width: image.width, height: image.height })) {
        resolve({ width: image.width, height: image.height });
      } else {
        reject();
      }
    };
    image.onerror = reject;
    reader.addEventListener("load", () => {
      image.src = reader.result;
    });
    reader.readAsDataURL(img);
  });
}

export function getImgWH(img) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = img;
    image.onload = function () {
      resolve({
        width: image.width,
        height: image.height,
      });
    };
    image.onerror = reject;
  });
}

// export const CompressorImg = (file, num = 0.8) => {
//   return new Promise((resolve) => {
//     new Compressor(file, {
//       quality: num,
//       success(res) {
//         resolve(res);
//       },
//     });
//   });
// };

export const getFirstPath = (data) => {
  let result;
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    if (element.path) {
      result = element.path;
      break;
    } else if (element.children && element.id !== 1) {
      result = getFirstPath(element.children);
    }
  }
  return result;
};
export function debounce(fn, wait) {
  var timer = null;
  return function () {
    var context = this;
    var args = arguments;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, wait);
  };
}
export function throttle(func, delay) {
  let timer = null;
  let startTime = Date.now();
  return function () {
    const curTime = Date.now();
    const remaining = delay - (curTime - startTime);
    let context = this;
    const args = arguments;
    clearTimeout(timer);
    if (remaining <= 0) {
      func.apply(context, args);
      startTime = Date.now();
    } else {
      timer = setTimeout(func, remaining);
    }
  };
}
export function isRegExp(v) {
  return _toString.call(v) === "[object RegExp]";
}

export function enquireScreen(call) {
  const handler = {
    match: function () {
      call && call(true);
    },
    unmatch: function () {
      call && call(false);
    },
  };
  enquireJs.register("only screen and (max-width: 767.99px)", handler);
}

const _toString = Object.prototype.toString;
