module.exports = {
	messages: {
		CN: {
			freeModalTitle: '试用期还剩 X 天',
			freeModelSubTitle: '过期后',
			freeModelTipTxt1: '销售代表核心功能不可使用',
			freeModelTipTxt2: '历史创建销售任务无法查看',
			freeModelTipTxt3: '无法使用高级数据看板',
			freeModeBtnTxt: '立即支付',
			freeModeBtnTxt1: '继续使用免费版本'
		},

		US: {
			freeModalTitle: 'X days left in the trial period',
			freeModelSubTitle: 'After expiration',
			freeModelTipTxt1: 'Core sales rep features will be unavailable',
			freeModelTipTxt2: 'Previously created sales campaigns cannot be viewed',
			freeModelTipTxt3: 'Advanced dashboards are inaccessible',
			freeModeBtnTxt: 'Pay now',
			freeModeBtnTxt1: 'Continue using the free version'
		},
		AR: {
			freeModalTitle: 'تبقّى X يومًا في فترة التجربة',
			freeModelSubTitle: 'بعد انتهاء الصلاحية',
			freeModelTipTxt1: 'لن تكون الميزات الأساسية لمندوبي المبيعات متاحة',
			freeModelTipTxt2: 'لا يمكن عرض المهام البيعية التي تم إنشاؤها سابقًا',
			freeModelTipTxt3: 'لا يمكن الوصول إلى لوحات البيانات المتقدمة',
			freeModeBtnTxt: 'قم بالترقية الآن',
			freeModeBtnTxt1: 'استمر في استخدام النسخة المجانية'
		},
	},
};