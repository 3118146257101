<template>
  <div class="noData">
    <img src="@/assets/img/notData.png" />
    <p :class="[lang]">{{ text ? text : "no data" }}</p>
  </div>
</template>
<script>
export default {
  props: ["text"],
  i18n: require("./i18n"),
};
</script>
<style lang="less" scoped>
.noData {
  width: 225px;
  img {
    width: 159px;
    height: 114px;
    display: block;
    margin: 0 auto;
  }
  p {
    text-align: center;
    line-height: 24px;
    margin-top: 8px;
  }
}
</style>
