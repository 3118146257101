<template>
  <div class="footer">
    <div class="copyright">©Strategybrain Technology Ltd.</div>
    <div class="links">
      <!-- <a
        target="_blank"
        :key="index"
        :href="item.link ? item.link : 'javascript: void(0)'"
        v-for="(item, index) in linkList"
      >
        <a-icon v-if="item.icon" :type="item.icon" />{{ item.name }}
      </a> -->
      <a
        target="_blank"
        :class="[lang]"
        href="https://www.strategybrain.ca/privacy-policy/"
      >
        {{ $t("PrivacyPolicy") }}
      </a>
      <a
        target="_blank"
        :class="[lang]"
        href="https://www.strategybrain.ca/news/customer-success/handbook/"
      >
        {{ $t("HelpCenter") }}
      </a>
      <a
        target="_blank"
        :class="[lang]"
        href="https://www.strategybrain.ca/contact-us/"
      >
        {{ $t("ContactUs") }}
      </a>
      <a
        target="_blank"
        :class="[lang]"
        href="https://www.strategybrain.ca/faqs/"
      >
        {{ $t("FAQ") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFooter",
  props: ["copyright", "linkList"],
  i18n: {
    messages: {
      CN: {
        PrivacyPolicy: "隐私条款",
        HelpCenter: "帮助中心",
        ContactUs: "联系我们",
        FAQ: "常见问题",
      },
      US: {
        PrivacyPolicy: "Privacy Policy",
        HelpCenter: "Help Center",
        ContactUs: "Contact Us",
        FAQ: "FAQ",
      },
      AR: {
        PrivacyPolicy: "سياسة الخصوصية",
        HelpCenter: "مركز المساعدة",
        ContactUs: "اتصل بنا",
        FAQ: "الأسئلة الشائعة",
      },
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  user-select: none;
  padding: 0 30px;
  /*margin: 48px 0 24px;*/
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  .copyright {
    color: @text-color-second;
    font-size: 14px;
    i {
      margin: 0 4px;
    }
  }
  .links {
    margin-bottom: 8px;
    // a:not(:last-child) {
    //   margin-right: 40px;
    // }
    a {
      height: 17px;
      line-height: 17px;
      font-size: 13px;
      display: inline-block;
      color: #0e756a;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      padding: 0 50px;
      position: relative;
    }
    a::after {
      content: "";
      position: absolute;
      height: 11px;
      width: 1px;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background: #dedede;
    }
    a:last-child {
      padding-right: 0;
      ::after {
        background: none;
      }
    }
  }
}
</style>
