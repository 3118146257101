import Cookie from "js-cookie";
import crypto from "crypto";
// import { throttle } from "./util";
import Vue from "vue";
import { v4 as uuidv4 } from 'uuid';

// import { changeMenu, Rolelimit } from '@/utils/operation.js'
// const handleMsg = function (message, res) {
//   return (() => {
//     throttle((res) => {
//       if (res) {
//         message.error(res.data.msg);
//       }
//     }, 300);
//   })(message, res);
// };
// 401拦截
const resp401 = {
  /**
   * 响应数据之前做点什么
   * @param response 响应对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(response, options) {
    const { message, router } = options;
    let { data } = response;
    // ;

    // if (data.code === 301009) {
    //   handleMsg(message, response);
    //   // Vue.prototype.$message.error(response.data.msg)
    //   localStorage.clear();
    //   router.push("/login");
    //   return data;
    // }
    // if (data.code === 301011) {
    //   console.log("权限变更");
    //   let ele = document.getElementById("permissModal");
    //   if (!ele) {
    //     new Vue().$noPermission.show().open(router);
    //   }
    //   return data;
    // }
    // if (response.headers["content-type"] == "application/octet-stream") {
    //   return response;
    // }

    // if (
    //   response.headers["content-type"] ==
    //   "application/vnd.ms-excel;charset=utf-8"
    // ) {
    //   return response;
    // }

    // if (response.headers["content-type"] == "application/force-download") {
    //   return response;
    // }
    if (data.code != 200 && data.code != 201) {
      if (document.getElementsByClassName("message-customer").length == 0) {
        message.error(data.msg);
      }
      if (data.code == 401) {
        router.push("/login");
        return;
      }
    }
    return response.data;
  },
  /**
   * 响应出错时执行
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const { message } = options;
    const { response } = error;
    console.log("请求错误, code: ", error.response);
    switch (response.status) {
      case 502:
        message.error(`服务器错误，code:${error.response.status}`);
        break;
      case 500:
        message.error(`服务器错误，code:${error.response.status}`);
        break;
      case 404:
        message.error(`服务端接口不存在，code:${error.response.status}`);
        break;
      case 400:
        message.error(`${response.data.msg}`);
        break;
      case 401:
        if (error.response.data.code === 301011) {
          let ele = document.getElementById("permissModal");
          if (!ele) {
            new Vue().$noPermission.show().open(options.router);
          }
        }
        break;
      default:
        break;
    }

    return Promise.reject(error);
  },
};

// const resp403 = {
//   onFulfilled(response, options) {
//     const {message} = options
//     let {data} = response
//
//     if (response.status === 403) {
//       message.error('请求被拒绝')
//     }
//     return data
//   },
//   onRejected(error, options) {
//     const {message} = options
//     const {response} = error
//     if (response.status === 403) {
//       message.error('请求被拒绝')
//     }
//     return Promise.reject(error)
//   }
// }

function getUuid () {
  let uuid = localStorage.getItem('StrategyBrainUUID');
  if (!uuid) {
    uuid = uuidv4().replace(/-/g, '');
    localStorage.setItem('StrategyBrainUUID', uuid);
  }
  return uuid;
}
// 获取cookie
function getCookiesByKeyword(keyword) {
  let allCookies = Cookie.get(); // 获取所有 cookie 的名称和值作为对象
  let matchingCookies = {};

  // 遍历所有 cookie 的名称
  for (let cookieName in allCookies) {
    // 检查 cookie 名称是否包含关键字
    if (cookieName.includes(keyword)) {
      // 如果包含关键字，则将该 cookie 添加到结果对象中
      matchingCookies[cookieName] = allCookies[cookieName];
    }
  }

  return matchingCookies;
}

const reqCommon = {
  /**
   * 发送请求之前做些什么
   * @param config axios config
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(config, options) {
    // const { message } = options;
    // const { url, xsrfCookieName } = config;
    console.log(options);
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json;charset=utf-8",
    };
    const login = config.url == "/user/login";
    const token = localStorage.getItem("token");
    const timeTemp = Date.now();
    config.headers["timestamp"] = timeTemp;
    let sortedData = Object.entries(config.data || {}).sort();
    let signStr =
      timeTemp + JSON.stringify(Object.fromEntries(sortedData)) + "s11n_secret";
    signStr = crypto.createHash("sha256").update(signStr).digest("hex");
    const lang = localStorage.getItem("lang");
    config.headers["lang"] = lang ? lang.toLocaleLowerCase() : "us";
    config.headers.signature = signStr;
    if (token && !login) {
      config.headers["authorization"] = token;
    }
    // header头添加uuid
    let uuid = getUuid();
    if (uuid) {
      config.headers["uuid"] = uuid;
    }
    // header头添加matomo_id
    let pk_id_cookies = getCookiesByKeyword('_pk_id');
    for (let cookieItem in pk_id_cookies) {
      if (pk_id_cookies[cookieItem]) {
        config.headers["matomoid"] = pk_id_cookies[cookieItem].split(".")[0];
      }
    }
    // if (
    //   url.indexOf("login") === -1 &&
    //   xsrfCookieName &&
    //   !Cookie.get(xsrfCookieName)
    // ) {
    //   message.warning("认证 token 已过期，请重新登录");
    // }
    return config;
  },
  /**
   * 请求出错时做点什么
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const { message } = options;
    message.error(error.message);
    return Promise.reject(error);
  },
};

export default {
  request: [reqCommon], // 请求拦截
  response: [resp401], // 响应拦截
};
