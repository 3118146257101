import TabsView from "@/layouts/tabs/TabsView";
import BlankView from "@/layouts/BlankView";
// import PageView from "@/layouts/PageView";

// 路由配置
const options = {
  routes: [
    {
      path: "/login",
      name: "登录页",
      component: () => import("@/pages/login"),
    },
    {
      path: "*",
      name: "404",
      component: () => import("@/pages/exception/404"),
    },
    {
      path: "/403",
      name: "403",
      component: () => import("@/pages/exception/403"),
    },
    {
      path: "/loginskip",
      name: "loginskip",
      component: () => import("@/pages/login/loginskip"),
    },
    {
      path: "/",
      name: "首页",
      component: TabsView,
      redirect: "/login",
      children: [
        {
          path: "sales",
          name: "Sales",
          meta: {
            icon: "menu-sale1",
            iconSelected: "menu-sale2",
          },
          component: BlankView,
          children: [
            {
              path: "salesReps",
              name: "Sales Reps",
              component: () => import("@/pages/sales/salesReps/salesBankView"),
              redirect: "/sales/salesReps/home",
              children: [
                {
                  path: "home",
                  name: "Home",
                  meta: {
                    invisible: true,
                  },
                  component: () => import("@/pages/sales/salesReps/home"),
                },
                {
                  path: "campaign",
                  name: "Campaign",
                  meta: {
                    invisible: true,
                  },
                  component: () => import("@/pages/sales/salesReps/campaign"),
                },
                {
                  path: "campaignDetail",
                  name: "Campaign Detail",
                  meta: {
                    invisible: true,
                    keepAlive: true,
                  },
                  component: () =>
                    import("@/pages/sales/salesReps/campaignDetail"),
                },
                {
                  path: "createCampaign",
                  name: "create campaign",
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                  component: () =>
                    import("@/pages/sales/salesReps/createCampaign"),
                },
                {
                  path: "dashboard",
                  name: "Dashboard",
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                  component: () => import("@/pages/sales/salesReps/dashboard"),
                },
                {
                  path: "linkin",
                  name: "LinkedIn Account Setting",
                  meta: {
                    invisible: true,
                  },
                  component: () => import("@/pages/sales/salesReps/linkin"),
                },
                {
                  path: "dialogue",
                  name: "dialogue",
                  meta: {
                    invisible: true,
                  },
                  component: () => import("@/pages/sales/salesReps/dialogue"),
                },
              ],
            },
            {
              name: "Marketing experts",
              path: "marketingExperts",
              component: () => import("@/pages/sales/salesReps/salesBankView"),
              meta: {
                disabled: true,
              },
            },
          ],
        },
        {
          path: "hr",
          name: "HR",
          meta: {
            icon: "menu-hr",
            iconSelected: "menu-hr2",
          },
          component: BlankView,
          children: [
            // {
            //   path: "hrExperts",
            //   name: "Hr Experts",
            //   component: () => import("@/pages/hr/hrExperts"),
            // },
            {
              path: "recruiter",
              name: "recruiter",
              component: () => import("@/pages/hr/recruiter/salesBankView"),
              redirect: "/hr/recruiter/home",
              children: [
                {
                  path: "home",
                  name: "Home",
                  meta: {
                    invisible: true,
                  },
                  component: () => import("@/pages/hr/recruiter/home"),
                },
                {
                  path: "campaign",
                  name: "Campaign",
                  meta: {
                    invisible: true,
                  },
                  component: () => import("@/pages/hr/recruiter/campaign"),
                },
                {
                  path: "campaignDetail",
                  name: "Campaign Detail",
                  meta: {
                    invisible: true,
                    keepAlive: true,
                  },
                  component: () =>
                    import("@/pages/hr/recruiter/campaignDetail"),
                },
                {
                  path: "createCampaign",
                  name: "create campaign",
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                  component: () =>
                    import("@/pages/hr/recruiter/createCampaign"),
                },
                {
                  path: "dashboard",
                  name: "Dashboard",
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                  component: () => import("@/pages/hr/recruiter/dashboard"),
                },
                {
                  path: "linkin",
                  name: "LinkedIn Account Setting",
                  meta: {
                    invisible: true,
                  },
                  component: () => import("@/pages/hr/recruiter/linkin"),
                },
                {
                  path: "dialogue",
                  name: "dialogue",
                  meta: {
                    invisible: true,
                  },
                  component: () => import("@/pages/hr/recruiter/dialogue"),
                },
              ],
            },
          ],
        },
        {
          path: "setting",
          name: "Setting",
          meta: {
            icon: "menu-setting",
            iconSelected: "menu-setting2",
          },
          component: BlankView,
          children: [
            {
              path: "userSetting",
              component: () => import("@/pages/setting/userSetting"),
              redirect: "/setting/userSetting/plan",
              meta: {
                invisible: true,
              },
              children: [
                {
                  path: "userProfile",
                  name: "User Profile",
                  component: () => import("@/pages/setting/userProfile/index"),
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                },
                {
                  path: "planStep2",
                  name: "Plan step2",
                  component: () => import("@/pages/setting/pricing/planstep2"),
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                },
                {
                  path: "planStep3",
                  name: "Plan step3",
                  component: () => import("@/pages/setting/pricing/planstep3"),
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                },
                {
                  path: "plan",
                  name: "Plan",
                  component: () => import("@/pages/setting/pricing/plan"),
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                },
              ],
            },
            {
              path: "order",
              name: "order",
              component: () => import("@/pages/setting/order/index"),
              meta: {
                page: {
                  cacheAble: false,
                },
              },
            },
            {
              path: "discount",
              name: "discount",
              component: () => import("@/pages/setting/discount/index"),
              meta: {
                invisible: true,
              },
            },
            {
              path: "bindAccount",
              name: "bindAccount",
              component: () =>
                import("@/pages/setting/pricing/bindAccount.vue"),
              meta: {
                invisible: true,
                page: {
                  cacheAble: false,
                },
              },
            },
          ],
        },
        {
          path: "commissionList",
          name: "CommissionList",
          meta: {
            icon: "commission",
            iconSelected: "commission-1",
            showLevel1: true,
            // invisible: true,
          },
          component: () => import("@/pages/commission/list"),
          // children: [
          //   {
          //     path: "list",
          //     name: "List",
          //     component: () => import("@/pages/commission/list"),
          //     meta: {
          //       invisible: true,
          //     },
          //   },
          // ],
        },

        {
          path: "exception",
          name: "异常页",
          meta: {
            icon: "warning",
            invisible: true,
          },
          component: BlankView,
          children: [
            {
              path: "404",
              name: "Exp404",
              component: () => import("@/pages/exception/404"),
            },
            {
              path: "403",
              name: "Exp403",
              component: () => import("@/pages/exception/403"),
            },
            {
              path: "500",
              name: "Exp500",
              component: () => import("@/pages/exception/500"),
            },
            {
              path: "502",
              name: "Exp502",
              meta: {
                invisible: true,
              },
              component: () => import("@/pages/exception/502"),
            },
            {
              path: "second",
              name: "二级页面",
              // meta: {
              //   invisible: true,
              // },
              component: () => import("@/pages/exception/second"),
              redirect: "/exception/second/101",
              children: [
                {
                  path: "101",
                  name: "101",
                  meta: {
                    invisible: true,
                  },
                  component: () => import("@/pages/exception/101"),
                },
                {
                  path: "102",
                  name: "102",
                  meta: {
                    invisible: true,
                  },
                  component: () => import("@/pages/exception/102"),
                },
                {
                  path: "103",
                  name: "103",
                  meta: {
                    invisible: true,
                  },
                  component: () => import("@/pages/exception/103"),
                },
                {
                  path: "104",
                  name: "104",
                  meta: {
                    invisible: true,
                  },
                  component: () => import("@/pages/exception/104"),
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default options;
