module.exports = {
  messages: {
    CN: {
      freeTrail: "免费版本还有**天到期",
      deadline: "[]年[]月[]日到期",
      price: "按年付费享受83折减免",
      LogOut: "退出登录",
      accountFreeDays: "账号[]免费试用还剩**天",
      UpgradeNow: "去升级",
    },

    US: {
      freeTrail: "** Days Left on Your Free Trial",
      deadline: "Expires on []/[]/[]",
      price: "Save 67% Now",
      LogOut: "Log Out",
      accountFreeDays: "Account [] has ** days left in the free trial",
      UpgradeNow: "Upgrade Now",
    },
    AR: {
      freeTrail: "متبقي ** أيام على انتهاء النسخة المجانية",
      deadline: "[]/[]/[]ينتهي في",
      price: "وفر الآن 67%",
      LogOut: "تسجيل الخروج",
      accountFreeDays: "الحساب ** يتبقى له [] يومًا في فترة التجربة المجانية",
      UpgradeNow: "ترقية الآن",
    },
  },
};
