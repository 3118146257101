<template>
  <a-layout-header :class="[headerTheme, 'admin-header', noshadown]">
    <div :class="['admin-header-wide', layout, pageWidth]">
      <router-link
        v-if="isMobile || layout === 'head'"
        to="/"
        :class="['logo', isMobile ? null : 'pc', headerTheme]"
      >
        <svg-icon iconClass="logo" class="logosvg"></svg-icon>
        <!-- <img width="32" src="@/assets/img/logo.png" /> -->
        <!-- <h1 v-if="!isMobile">{{ systemName }}</h1> -->
      </router-link>
      <!-- <a-divider v-if="isMobile" type="vertical" /> -->
      <!-- <a-icon
        v-if="layout !== 'head'"
        class="trigger"
        :type="collapsed ? 'menu-unfold' : 'menu-fold'"
        @click="toggleCollapse"
      /> -->
      <div
        class="freeTips"
        v-if="showToolbar && !haveAccount"
        @click="skipPay"
        style="cursor: pointer"
      >
        <a-icon
          type="exclamation-circle"
          style="font-size: 12px; color: #ffa200; margin-right: 5px"
        />
        <span :class="[lang]">{{
          $t("freeTrail").replace("**", havedays)
        }}</span>
        <a-icon
          type="right"
          style="font-size: 12px; color: #ffa200; margin-left: 5px"
        />
      </div>
      <div
        class="freeTips"
        v-if="showToolbar && haveAccount"
        style="cursor: pointer"
      >
        <a-icon
          type="exclamation-circle"
          style="font-size: 12px; color: #ffa200; margin-right: 5px"
        />
        <span :class="[lang]" v-html="haveAccountTips"></span>
        <span class="goUpgrade" @click="skipPay">{{ $t("UpgradeNow") }}</span>
      </div>
      <div
        v-if="layout !== 'side' && !isMobile"
        class="admin-header-menu"
        :style="`width: ${menuWidth};`"
      >
        <i-menu
          class="head-menu"
          :class="[lang]"
          :theme="headerTheme"
          mode="horizontal"
          :options="menuData"
          @select="onSelect"
        />
      </div>
      <div :class="['admin-header-right', headerTheme]">
        <!-- <header-search
          class="header-item"
          @active="(val) => (searchActive = val)"
        /> -->
        <!-- <a-tooltip class="header-item" title="帮助文档" placement="bottom" >
            <a href="https://iczer.gitee.io/vue-antd-admin-docs/" target="_blank">
              <a-icon type="question-circle-o" />
            </a>
          </a-tooltip> -->
        <!-- <header-notice class="header-item" /> -->
        <!-- <div class="">
          <a-button type="primary" size="large">{{ $t("price") }}</a-button>
        </div> -->
        <a-dropdown class="lang header-item">
          <div><a-icon type="global" /> {{ langAlias }}</div>
          <a-menu
            @click="(val) => setClang(val)"
            :selected-keys="[localLang ? localLang : lang]"
            slot="overlay"
          >
            <a-menu-item v-for="lang in langList" :key="lang.key">{{
              lang.name
            }}</a-menu-item>
          </a-menu>
        </a-dropdown>
        <header-avatar class="header-item" />
      </div>
    </div>
  </a-layout-header>
</template>

<script>
// import HeaderSearch from "./HeaderSearch";
// import HeaderNotice from "./HeaderNotice";
import HeaderAvatar from "./HeaderAvatar";
import IMenu from "@/components/menu/menu";
import { mapState, mapMutations } from "vuex";

export default {
  name: "AdminHeader",
  components: { IMenu, HeaderAvatar },
  props: ["collapsed", "menuData"],
  data() {
    return {
      freeTipsTimer: null,
      showToolbar: false,
      haveAccount: null,
      havedays: 0,
      langList: [
        { key: "CN", name: "简体中文", alias: "简体" },
        { key: "AR", name: "اللغة العربية", alias: "اللغة العربية" },
        // { key: "HK", name: "繁體中文", alias: "繁體" },
        { key: "US", name: "English", alias: "English" },
      ],
      searchActive: false,
      localLang: localStorage.getItem("lang"),
    };
  },
  i18n: require("./i18n"),
  computed: {
    ...mapState("setting", [
      "theme",
      "isMobile",
      "layout",
      "systemName",
      "lang",
      "pageWidth",
    ]),
    haveAccountTips() {
      return this.$t("accountFreeDays")
        .replace("**", `<span class='days'>${this.havedays}</span>`)
        .replace("[]", `<span class='account'>${this.haveAccount}</span>`);
    },
    noshadown() {
      if (
        this.$route.fullPath.indexOf("/sales/salesReps/") > -1 ||
        this.$route.fullPath.indexOf("/setting/userSetting/") > -1 ||
        this.$route.fullPath.indexOf("/setting/order") > -1
      ) {
        return "noshadown";
      } else {
        return "";
      }
    },
    headerTheme() {
      // if (
      //   this.layout == "side" &&
      //   this.theme.mode == "dark" &&
      //   !this.isMobile
      // ) {
      //   return "light";
      // }
      // return this.theme.mode;
      return "light";
    },
    langAlias() {
      let lang = this.langList.find(
        (item) => item.key == (this.localLang ? this.localLang : this.lang)
      );
      return lang.alias;
    },
    menuWidth() {
      const { layout, searchActive } = this;
      const headWidth = layout === "head" ? "100% - 188px" : "100%";
      const extraWidth = searchActive ? "600px" : "400px";
      return `calc(${headWidth} - ${extraWidth})`;
    },
  },

  methods: {
    skipPay() {
      if (this.user.free_info.account) {
        this.$router.push({
          path: "/setting/userSetting/plan",
          query: {
            // 有账户时可以在付完费直接绑定
            upgrade_free: this.user.free_info.account ? 1 : 0,
            campaign_type: 1,
            //campaign_type: this.user.free_info.bind_type,
          },
        });
      } else {
        this.$router.push({
          path: "/setting/userSetting/plan",
          query: {
            campaign_type: 1,
          },
        });
      }
    },

    initFreeInfo() {
      //this.getTimes(new Date("2024-11-17 22:27:24"));
      // 1970-01-01T00:00:00Z
      // this.formatTimeZoneTime("2024-11-18 20:26:24");
      let free_info =
        this.user && this.user.free_info ? this.user.free_info : null;
      // console.log(free_info);
      if (free_info) {
        let { end_date, account } = free_info;
        // let start = new Date(start_date).getTime();
        let end = new Date(end_date).getTime();
        this.haveAccount = account;
        if (this.serverTime > end) {
          this.showToolbar = false;
        } else {
          this.showToolbar = true;
        }
      } else {
        this.showToolbar = false;
      }

      if (this.showToolbar) {
        let { end_date } = free_info;
        let end = new Date(end_date).getTime() - 3000;
        this.havedays = Math.ceil((end - Date.now()) / (24 * 60 * 60 * 1000));
      } else {
        this.havedays = 0;
      }
    },
    setClang(val) {
      this.localLang = val.key;
      localStorage.setItem("lang", val.key);
      this.setLang(val.key);
    },
    toggleCollapse() {
      this.$emit("toggleCollapse");
    },
    onSelect(obj) {
      this.$emit("menuSelect", obj);
    },
    ...mapMutations("setting", ["setLang"]),
  },
  mounted() {
    this.getServiceTime().then((serverTime) => {
      let current = serverTime;
      current += 1000;
      this.freeTipsTimer = setInterval(() => {
        let free_info =
          this.user && this.user.free_info ? this.user.free_info : null;
        // console.log(free_info);
        if (free_info) {
          let { end_date, account } = free_info;
          // let start = new Date(start_date).getTime();
          let end = new Date(end_date).getTime();
          this.haveAccount = account;
          if (current > end) {
            this.showToolbar = false;
          } else {
            this.showToolbar = true;
          }
        } else {
          this.showToolbar = false;
        }

        if (this.showToolbar) {
          let { end_date } = free_info;
          let end = new Date(end_date).getTime() - 3000;
          this.havedays = Math.ceil((end - Date.now()) / (24 * 60 * 60 * 1000));
        } else {
          this.havedays = 0;
        }
      }, 1000);
    });
  },
  destroyed() {
    if (this.freeTipsTimer) {
      clearInterval(this.freeTipsTimer);
      this.freeTipsTimer = null;
    }
  },
};
</script>

<style lang="less" scoped>
@import "index";
.noshadown {
  box-shadow: none !important;
}
.admin-header {
  z-index: 100;
}
.freeTips {
  position: absolute;
  padding-right: 20px;
  height: 44px;
  padding-left: 14px;
  line-height: 44px;
  background: #fcf1df;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #ffbb00;
  font-size: 18px;
  color: #302525;
  // color: red;
  left: 19px;
  top: 10px;
  display: flex;
  align-items: center;
}

/deep/.days {
  text-decoration: none;
  font-weight: bold;
  color: #0e756a;
}
/deep/.account {
  display: inline-block;
  margin: 0 5px;
  color: #0e756a;
  font-weight: bold;
  color: #0e756a;
}
.goUpgrade {
  // width: 67px;
  height: 29px;
  background: linear-gradient(180deg, #ffac00 0%, #f85760 100%);
  border-radius: 15px 15px 15px 15px;
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  line-height: 29px;
  text-align: center;
  cursor: pointer;
  padding: 0 12px;
  margin-left: 40px;
}
</style>
