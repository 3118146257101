var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "message-fade" } }, [
    _vm.visible
      ? _c("div", { staticClass: "message-customer mask-bg" }, [
          _c(
            "div",
            { staticClass: "message", class: _vm.wrapClasses },
            [
              _vm.type == "error"
                ? _c("a-icon", {
                    staticClass: "icon",
                    staticStyle: { color: "#f9593a" },
                    attrs: { type: "close-circle", theme: "filled" }
                  })
                : _vm._e(),
              _vm.type == "success"
                ? _c("a-icon", {
                    staticClass: "icon",
                    staticStyle: { color: "#52c41a" },
                    attrs: { type: "check-circle", theme: "filled" }
                  })
                : _vm._e(),
              _vm.type == "warning"
                ? _c("a-icon", {
                    staticClass: "icon",
                    staticStyle: { color: "#faad14" },
                    attrs: { type: "check-circle", theme: "filled" }
                  })
                : _vm._e(),
              _vm.type == "info"
                ? _c("a-icon", {
                    staticClass: "icon",
                    staticStyle: { color: "#1890ff" },
                    attrs: { type: "check-circle", theme: "filled" }
                  })
                : _vm._e(),
              _c("span", [_vm._v(_vm._s(_vm.message))])
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }