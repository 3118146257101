<template>
	<FreeModal />
</template>

<script>
import FreeModal from './FreeModal.vue'
export default {
	name: 'GlobalModals',
	components: {
		FreeModal
	}
}
</script>

<style scoped>

</style>
