<template>
  <!-- 七天试用期内弹框 -->
  <a-modal
    v-if="visible"
    v-model="visible"
    title=""
    :centered="true"
    :footer="null"
    :maskClosable="false"
    dialogClass="global-free-modal-class"
    :closeIcon="
      (h) =>
        h('svg-icon', {
          props: { iconClass: 'd_close' },
          style: {
            fontSize: '23px',
          },
        })
    "
    @cancel="closeModal"
  >
    <div class="free-modal-img"></div>
    <div class="free-modal-con">
      <div class="top-box">
        <div class="title-txt" :class="[lang]">
          {{ $t("freeModalTitle").replace("X", havedays) }}
        </div>
        <div class="title-tip" :class="[lang]">
          {{ $t("freeModelSubTitle") }}
        </div>
      </div>
      <div class="tip-box" :class="[lang]">
        <div
          class="tip-item"
          :class="[lang]"
          :style="{
            'text-align': lang == 'AR' ? 'right' : 'left',
          }"
        >
          <svg-icon
            :style="{
              'margin-left': lang == 'AR' ? '8px' : '0',
            }"
            iconClass="d_right"
            class="tip-txt-icon"
          ></svg-icon>
          {{ $t("freeModelTipTxt1") }}
        </div>
        <div
          class="tip-item"
          :class="[lang]"
          :style="{
            'text-align': lang == 'AR' ? 'right' : 'left',
          }"
        >
          <svg-icon
            :style="{
              'margin-left': lang == 'AR' ? '8px' : '0',
            }"
            iconClass="d_right"
            class="tip-txt-icon"
          ></svg-icon>
          {{ $t("freeModelTipTxt2") }}
        </div>
        <div
          class="tip-item"
          :class="[lang]"
          :style="{
            'text-align': lang == 'AR' ? 'right' : 'left',
          }"
        >
          <svg-icon
            :style="{
              'margin-left': lang == 'AR' ? '8px' : '0',
            }"
            iconClass="d_right"
            class="tip-txt-icon"
          ></svg-icon>
          {{ $t("freeModelTipTxt3") }}
        </div>
      </div>
      <div class="model-btn" :class="[lang]" @click="goPayStep1">
        {{ $t("freeModeBtnTxt") }}
      </div>
      <div class="free-btn" :class="[lang]" @click="closeModal">
        {{ $t("freeModeBtnTxt1") }}
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      havedays: 0,
    };
  },
  i18n: require("./i18n"),
  computed: {
    ...mapState("setting", ["lang"]),
  },
  methods: {
    // 关闭弹框回调
    closeModal() {
      this.visible = false;
    },
    // 判断是否显示弹框，试用期内展示弹框
    isShowModal() {
      if (this.user && this.user.free_info) {
        // 一天校验一次
        let showTime = localStorage.getItem("showFreeModal");
        let newDate =
          new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate();
        // if (showTime && Date.now() - new Date(showTime).getTime() < 24 * 60 * 60 * 1000) {
        if (showTime && showTime == newDate) {
          return false; // 一天内不展示弹框
        }
        localStorage.setItem("showFreeModal", newDate); // 存储今天时间
        let { end_date } = this.user.free_info;
        // let start = new Date(start_date).getTime();
        let end = new Date(end_date).getTime();
        if (Date.now() > end) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    getDays() {
      if (this.visible) {
        let { end_date } = this.user.free_info;
        let end = new Date(end_date).getTime() - 3000;
        let diffTime = Math.ceil((end - Date.now()) / (24 * 60 * 60 * 1000));
        return diffTime > 0 ? diffTime : 0;
      } else {
        return 0;
      }
    },
    // 进入支付第一步
    goPayStep1() {
      this.closeModal();
      if (this.user && this.user.free_info && this.user.free_info.account) {
        this.$router.push({
          path: "/setting/userSetting/plan",
          query: {
            // 有账户时可以在付完费直接绑定
            upgrade_free: this.user.free_info.account ? 1 : 0,
            // 默认选中1
            campaign_type: this.user.free_info.bind_type
              ? this.user.free_info.bind_type
              : 1,
          },
        });
      } else {
        this.$router.push({
          path: "/setting/userSetting/plan",
					query: {
            campaign_type: 1,
          },
        });
      }
    },
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler() {
        if (
          this.$route.query.isNew == 1 ||
          localStorage.getItem("showGuide") == "1"
        ) {
          return;
        }
        this.visible = this.isShowModal();
        this.havedays = this.getDays();
      },
    },
  },
};
</script>
<style lang="less" scoped>
.free-modal-img {
  position: absolute;
  top: -36px;
  width: 454px;
  height: 223px;
  left: 0;
  overflow: hidden;
  background: url("../../assets/img/d_bg.png") top left no-repeat;
}
.free-modal-con {
  padding: 0 36px 24px 36px;
  position: relative;
  top: 0;
  text-align: center;
  .top-box {
    height: 188px;
  }
  .title-txt {
    padding-top: 88px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    text-align: center;
  }
  .title-tip {
    padding-top: 6px;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    text-align: center;
  }
  .tip-box {
    padding: 20px 0 0;
    display: inline-block;
    .tip-item {
      display: flex;
      .tip-txt-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 8px;
        margin-top: 3px;
      }
      line-height: 22px;
      font-size: 16px;
      color: #000;
      font-weight: 400;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 30px;
      }
    }
  }
  .model-btn {
    width: 100%;
    height: 48px;
    line-height: 48px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    background: #10756a;
    border-radius: 8px;
    margin-bottom: 24px;
    text-align: center;
    cursor: pointer;
  }
  .free-btn {
    font-weight: bold;
    font-size: 16px;
    color: #10756a;
    text-align: center;
    cursor: pointer;
  }
}
</style>
<style lang="less">
.global-free-modal-class {
  width: 454px !important;
  .ant-modal-body {
    padding: 0;
    position: relative;
  }
  .ant-modal-close {
    right: -4px;
    top: -2px;
  }
}
</style>
