var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.disabled
    ? _c(
        "transition",
        {
          attrs: {
            "enter-active-class":
              "animated " + _vm.enterAnimate + " page-toggle-enter-active",
            "leave-active-class":
              "animated " + _vm.leaveAnimate + " page-toggle-leave-active"
          }
        },
        [_vm._t("default")],
        2
      )
    : _c("div", [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }