module.exports = {
  messages: {
    CN: {
      home: "首页",
    },
    US: {
      home: "Home",
    },
    AR: {
      home: "الصفحة الرئيسية",
    },
  },
};
