// antd 配置
const ANTD = {
  primary: {
    color: "#0E756A",
    warning: "#faad14",
    success: "#52c41a",
    error: "#f5222d",
    light: {
      menuColors: ["#000c17", "#333b49", "#002140"],
    },
    dark: {
      menuColors: ["#333b49", "#333b49", "#002140"],
    },
    night: {
      menuColors: ["#151515", "#1f1f1f", "#1e1e1e"],
    },
  },
  theme: {
    dark: {
      "layout-body-background": "#f0f2f5",
      "body-background": "#fff",
      "component-background": "#fff",
      "heading-color": "rgba(0, 0, 0, 0.85)",
      "text-color": "rgba(0, 0, 0, 0.65)",
      "text-color-inverse": "#fefefe",
      "text-color-secondary": "rgba(0, 0, 0, 0.45)",
      "shadow-color": "rgba(0, 0, 0, 0.15)",
      "border-color-split": "#f0f0f0",
      "border-color-base": "#d9d9d9",
      "background-color-light": "#fafafa",
      "background-color-base": "#f5f5f5",
      "table-selected-row-bg": "#fafafa",
      "table-expanded-row-bg": "#fbfbfb",
      "checkbox-check-color": "#fff",
      "disabled-color": "rgba(0, 0, 0, 0.25)",
      "menu-dark-color": "rgba(254, 254, 254, 0.65)",
      "menu-dark-highlight-color": "#fefefe",
      "menu-dark-selected-item-icon-color": "#fefefe",
      "menu-dark-arrow-color": "#fefefe",
      "btn-primary-color": "#fefefe",
    },
    light: {
      "layout-body-background": "#f0f2f5",
      "body-background": "#fff",
      "component-background": "#fff",
      "heading-color": "rgba(0, 0, 0, 0.85)",
      "text-color": "rgba(0, 0, 0, 0.65)",
      "text-color-inverse": "#fefefe",
      "text-color-secondary": "rgba(0, 0, 0, 0.45)",
      "shadow-color": "rgba(0, 0, 0, 0.15)",
      "border-color-split": "#f0f0f0",
      "border-color-base": "#d9d9d9",
      "background-color-light": "#fafafa",
      "background-color-base": "#f5f5f5",
      "table-selected-row-bg": "#fafafa",
      "table-expanded-row-bg": "#fbfbfb",
      "checkbox-check-color": "#fff",
      "disabled-color": "rgba(0, 0, 0, 0.25)",
      "menu-dark-color": "rgba(1, 1, 1, 0.65)",
      "menu-dark-highlight-color": "#fefefe",
      "menu-dark-selected-item-icon-color": "#fefefe",
      "menu-dark-arrow-color": "#fefefe",
      "btn-primary-color": "#fefefe",
    },
    night: {
      "layout-body-background": "#000",
      "body-background": "#141414",
      "component-background": "#141414",
      "heading-color": "rgba(255, 255, 255, 0.85)",
      "text-color": "rgba(255, 255, 255, 0.85)",
      "text-color-inverse": "#fefefe",
      "text-color-secondary": "rgba(255, 255, 255, 0.45)",
      "shadow-color": "rgba(255, 255, 255, 0.15)",
      "border-color-split": "#303030",
      "border-color-base": "#282828",
      "background-color-light": "#ffffff0a",
      "background-color-base": "#2a2a2a",
      "table-selected-row-bg": "#ffffff0a",
      "table-expanded-row-bg": "#ffffff0b",
      "checkbox-check-color": "#141414",
      "disabled-color": "rgba(255, 255, 255, 0.25)",
      "menu-dark-color": "rgba(254, 254, 254, 0.65)",
      "menu-dark-highlight-color": "#fefefe",
      "menu-dark-selected-item-icon-color": "#fefefe",
      "menu-dark-arrow-color": "#fefefe",
      "btn-primary-color": "#fefefe",
    },
  },
};
module.exports = ANTD;
