<template>
  <transition name="message-fade">
    <div class="message-customer mask-bg" v-if="visible">
      <div :class="wrapClasses" class="message">
        <!-- <img class="message_img" :src="typeImg" /> -->
        <!-- <a-icon type="close-circle" style="color: red" /> -->
        <a-icon
          type="close-circle"
          v-if="type == 'error'"
          theme="filled"
          style="color: #f9593a"
          class="icon"
        />
        <a-icon
          class="icon"
          type="check-circle"
          v-if="type == 'success'"
          theme="filled"
          style="color: #52c41a"
        />
        <a-icon
          class="icon"
          type="check-circle"
          v-if="type == 'warning'"
          theme="filled"
          style="color: #faad14"
        />
        <a-icon
          class="icon"
          type="check-circle"
          v-if="type == 'info'"
          theme="filled"
          style="color: #1890ff"
        />
        <!-- <a-icon type="check-circle" style="color: #52c41a" />
        <a-icon type="exclamation-circle" style="color: #faad14" /> -->
        <span>{{ message }}</span>
      </div>
    </div>
  </transition>
</template>
<script>
const prefixCls = "message";
export default {
  name: "message",
  data() {
    return {
      visible: false,
      type: "info",
      message: "",
      duration: 3000,
    };
  },
  computed: {
    // typeImg() {
    //   return require(`../../assets/${this.type}.svg`);
    // },
    wrapClasses() {
      return [`${prefixCls}`, `${prefixCls}-${this.type}`];
    },
  },
  mounted() {
    // 挂载的时候就开始计时，3000ms后消失
    this.setTimer();
  },
  methods: {
    setTimer() {
      setTimeout(() => {
        // 3000ms之后调用关闭方法
        this.handleClose();
      }, this.duration);
    },
    handleClose() {
      this.visible = false;
      // 从DOM里将这个组件移除
      // visible只是控制了显示与隐藏  但是dom结构中还是存在组件  为了避免消耗内存必须销毁组件
      setTimeout(() => {
        this.$el.parentNode.removeChild(this.$el);
      }, 500);
    },
  },
};
</script>
<style lang="less" scoped>
.message-fade-enter-active,
.message-fade-leave-active {
  transition: all 0.3s ease;
}
.message-fade-enter, .message-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(-20px);
  opacity: 0;
}
.message {
  position: fixed;

  top: 50%;
  left: 50%;
  min-width: 400px;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  padding: 13px 18px;
  color: #616060;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  z-index: 1000;
}
.message_img {
  width: 18px;
  margin-right: 6px;
}
.message-success {
  background: #fff;
  color: #000;
  // background: #dcffefd3;
  // color: rgb(48, 194, 104);
}
.message-info {
  // background: #2db7f5;
  background: #fff;
  color: #000;
}
.message-warning {
  // background: #ffdec9;
  // color: #ecae51;
  background: #fff;
  color: #000;
}
.message-error {
  background: #fff;
  color: #000;
  // background: #ffe2e2;
  // color: rgb(255, 108, 108);
}
.mask-bg {
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.icon {
  margin-right: 5px;
}
</style>
